@import '../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../style/globalStyles.scss';
@import '../../../../../../assets/style/base/designTheme.scss';

.geometry-analysis {
	&--alert {
		z-index: 5000 !important;

		&.full-screen {
			margin-top: 50px !important;
			max-height: 90%;
		}

		&:not(.full-screen) {
			width: 600px !important;
			height: 70vh;
			max-height: 675px !important;

			&.drawing-alert {
				min-height: 620px;
				height: fit-content;

				@include breakpoint(1280px) {
					min-height: 760px;
				}
			}

			.text-muted {
				> div {
					align-items: center;
				}
			}

			.castor-alert--body {
				width: 500px !important;
				height: 64vh;
				max-height: 500px !important;
				padding: 0 !important;
				overflow: visible;

				.geometry-analysis--content {
					height: 100%;
					margin-top: 10px;
				}

				.geometry-analysis--content--part-display--viewer {
					width: 500px;
					height: 45vh;
					max-height: 500px;

					@include breakpoint(lg) {
						height: 50vh;
					}
				}
			}
		}
	}

	&--content {
		@include flex(flex-start, null);
		height: 80%;

		&.drawing-geometry-analysis {
			height: 65vh !important;
		}

		&--part-display {
			min-width: $part-display-width;
			margin-right: 20px;
			height: 100%;

			&--header {
				@include flex(center, space-between);
				margin-bottom: 5px;
				color: $text-regular-opac;
				font-weight: bold;

				&--checkbox {
					&#{&} {
						width: 30px;
						height: 21px;
					}

					&--label {
						&#{&} {
							margin-right: 0;
						}

						&--text {
							&#{&} {
								color: $text-regular-opac;
							}
						}
					}
				}
			}

			&--viewer {
				border: 1px solid #8484843b;
				border-radius: 5px;
				position: relative;
				height: 100%;
				background-color: white;

				.x3dom-canvas {
					position: absolute;
					top: 0;
					left: 0;
				}

				&--non-present-issue {
					position: absolute;
					bottom: 0;
					width: 100%;
					display: flex;
					align-items: center;
					opacity: 0.79;
					background-color: $text-regular-opac;
					color: $text-white;
					padding-left: 20px;
					padding: 21px 20px 20px 23px;
					border-radius: 0 0 5px 5px;
				}
			}
		}

		&--print-issues {
			width: 100%;

			&--footer {
				margin-top: 15px;
				color: $text-regular-opac;

				&--no-errors {
					margin-bottom: 10px;
				}
			}

			&--table {
				&#{&} {
					grid-row-gap: 10px;
					color: $text-regular-opac;
					height: 100%;
					max-height: 50vh;
					overflow-y: scroll;

					@include styled-scroll();
				}

				&--button {
					&#{&} {
						padding: 10px 15px;
						width: 150px;
						max-height: 50px;
						align-self: center;
					}

					&--icon {
						width: 20px;
						height: 20px;
						margin-right: 10px;
					}

					&--only-border {
						&#{&} {
							background-color: transparent;
							color: $text-regular-opac;
							padding: 10px 15px;
							box-shadow: unset;
							border: 1px solid $text-regular-opac;
							width: 150px;
							max-height: 50px;
							align-self: center;
							margin-right: 5px;
						}

						&:hover {
							&#{&} {
								background-color: transparent;
								color: $text-regular-opac;
								box-shadow: unset;
							}
						}
					}
				}
			}

			&--title {
				background: unset;
				padding-top: unset !important;
				padding-bottom: unset !important;
				margin-bottom: -4px;
			}
		}

		&--actions-wrapper {
			width: 100%;

			&.wide-buttons {
				button {
					width: 230px !important;
				}
			}
		}
	}
}
