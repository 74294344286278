.admin-clusters {
	&--loader {
		align-items: center;
		position: absolute;
		width: -webkit-fill-available;
		margin: 20px;
		bottom: 80px;
	}

	&--image {
		width: 100%;
	}

	&--result {
		font-weight: bold;
	}

	&--action-button {
		&#{&} {
			margin: 1px 0;
			width: 7em !important;
			padding: 6px 0;
			text-transform: unset;
			justify-content: space-evenly;
		}
	}

	&--buttons {
		position: absolute;
		top: -40px;
		right: 30px;
		&--button {
			&#{&} {
				text-transform: unset;
			}
			&--publish {
				&#{&} {
					width: 15em;
				}
			}
		}
	}
}
.align-start {
	span {
		font-weight: 500;
	}
}
.infinite-scroll-component {
	position: relative;
	min-height: 100px;
}
