@import '../../../../../assets/style/base/colorsTheme.scss';

.admin-project-form {
	justify-content: space-between !important;
	&--field {
		width: 50%;
		margin: 10px 0;
		text-transform: capitalize;
	}
	.admin-project-form {
		justify-content: flex-start !important;
		b {
			font-weight: 400;
		}
		h3, h4 {
			font-weight: 500;
		}
	}
}
.admin-project--info-button {
	display: inline-block;
	vertical-align: sub;
	margin-left: 5px;
	svg {
		width: 16px;
		height: 16px;
		path {
			&:nth-child(2) {
				fill: $blackColor;
			}
		}
	}
}
