@import '../../../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../../../style/globalStyles.scss';

.pdf-preview {
	&--alert {
		z-index: 10000;
		max-width: 900px !important;
		max-height: 700px !important;
		width: calc(
			100% - 300px
		) !important; //calculate the popup width with the sidebar
		height: calc(100% - 94px) !important; //calculate the popup height
		top: 10% !important;
		left: 27% !important;
		margin-top: unset !important;
		margin-left: unset !important;
		animation-timing-function: ease-in;
		animation-duration: 0.1s;
	}

	&--alert-body {
		max-height: 100%;
		padding: 0 !important;
		overflow: hidden;
	}

	&--wrapp {
		display: grid !important;
		padding: 5px 1px 1px 1px !important;
		grid-template-columns: 90% 10%;
		grid-template-rows: 80% 20%;
		grid-template-areas:
			'pdfContent sidebar'
			'footer footer';
		column-gap: 10px;
		row-gap: 10px;
		top: 50% !important;
		overflow: hidden;
		height: 620px;
	}

	&--body {
		max-height: 480px;
		overflow: auto;
		margin-top: 10px;

		> div {
			@include flex(center, center);
		}
		&.scaled {
			> div {
				@include flex(center, flex-start);
			}
		}
		&.integrated {
			@media screen and (max-width: 2200px) {
				> div {
					@include flex(center, flex-start);
				}
			}
		}

		&__pdf {
			max-height: 530px;
			height: 100%;
			overflow: auto;

			&#{&} {
				grid-area: pdfContent;
				place-self: center stretch;
				overflow: auto;
				max-width: 850px;
				user-select: none;
			}
			&::-webkit-scrollbar {
				width: 7px;
				height: 7px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: rgba(168, 168, 168, 0.7);
				border-radius: 10px;
			}
		}

		&__zoom {
			&#{&} {
				grid-area: sidebar;
				padding-right: 20px;
				top: 20%;
				right: 0;
				margin: 0;
				width: auto;
				flex-direction: column;
				@include flex();

				a {
					&:hover,
					&:visited {
						color: white;
					}
				}
			}
		}

		&__buttons {
			&#{&} {
				grid-area: footer;
				place-self: center stretch;
				left: 42%;
				margin: 0;
				@include flex();
				p {
					margin: 0 10px;
				}
			}
		}
	}

	@include breakpoint(2000px) {
		top: 30% !important;
		min-width: 950px;
	}

	h2 {
		margin: 0;
	}

	.text-muted {
		width: 100%;
		height: 80%;
	}
}
