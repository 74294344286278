@import '../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../style/globalStyles.scss';

$blockHeight: 516px;
$autoHeight: auto;
$headerBackground: #f9f9f9;
$svgSmall: 0.8em;
$svgBig: 1.2em;

.without-assembly-image {
	min-height: 610px;
	> div {
		height: 610px !important;
	}

	@include breakpoint(1520px) {
		min-height: $blockHeight;
		> div {
			height: $blockHeight !important;
		}
	}
	@include breakpoint(1440px) {
		min-height: 540px;
		> div {
			height: 540px !important;
		}
	}

	&.small {
		min-height: 0;
	}
}

.project-results {
	&--cost-saving-chart {
		display: flex;
		justify-content: center;
		overflow: visible;

		* {
			font-family: inherit !important; //override inline
		}

		&--gradient {
			height: 0;
			width: 0;
		}

		&.limited-height {
			margin-top: -22px;
			height: 300px;
		}

		&.grayed-out {
			opacity: 0.5;
		}
	}

	&--label {
		&--title {
			font-size: 16px;
			line-height: 19px;

			&--icon {
				width: 1.8rem;
				height: 1.8rem;

				&.grid-icon {
					width: 1.5rem;
					height: 1.5rem;
					margin-right: 10px;

					circle {
						fill: $warning;
					}

					rect {
						stroke: $warning;
					}
				}

				&.benefit-sign {
					.icons--benefits--text {
						fill: white;
					}
				}
			}

			&--out-of {
				margin-right: 5px;
				margin-top: 5px;
			}

			&--printable {
				font-weight: bold;
			}

			&--info {
				@include flexible-row();
				justify-content: initial;
			}

			.high {
				min-height: 40px;
			}

			&.without-content {
				height: 100%;

				.project-results--label--info {
					@include flexible-column();
					justify-content: center;
					height: 100%;
					margin-left: 0;
				}
			}
		}

		&--text {
			margin-left: 12px;
			color: $darkTextColor;
			font-size: 18px;
			line-height: 20px;
			font-weight: 600;

			@include breakpoint(xl) {
				font-size: 20px;
				line-height: 23px;
			}
		}

		&--index {
			font-weight: 600;
			font-size: 16px;
			line-height: 19px;
			color: $darkTextColor;
			margin-top: -15px;

			h5 {
				font-weight: bold;
				margin-bottom: 0;
				margin-top: 0;
				line-height: 20px;
			}

			&.limited-height {
				margin-top: -5px !important;
			}

			div {
				&.radio-buttons-grid {
					display: grid;
					grid-template-columns: 1fr;
					grid-template-rows: repeat(3, 1fr);
					gap: 0 0;
					margin-top: 5px;

					label {
						> span {
							margin: 3px !important;

							@include breakpoint(1440px) {
								margin: 2px !important;
							}

							@include breakpoint(1680px) {
								margin-right: 9px !important;
							}
						}
					}
				}

				@include breakpoint(1440px) {
					grid-template-columns: 1fr 1fr !important;
					grid-template-rows: repeat(3, 40px);
				}
			}

			.limited-width {
				grid-template-columns: inherit !important;
				justify-content: space-between;
				padding-right: 5%;
				max-height: 175px;

				svg {
					width: $svgSmall;
					height: $svgSmall;
				}

				label {
					margin-right: 0;

					> span:first-of-type {
						width: 20px;

						@include breakpoint(1440px) {
							width: 45px;
						}
					}
				}

				@include breakpoint(1280px) {
					grid-template-columns: repeat(2, 140px);
				}

				@include breakpoint(1440px) {
					grid-template-columns: repeat(2, 170px) !important;

					svg {
						width: $svgBig;
						height: $svgBig;
					}
				}

				@include breakpoint(1680px) {
					grid-template-columns: repeat(2, 185px) !important;
				}
			}
		}

		&--info {
			margin-left: 40px;
		}
	}

	&--block {
		margin-right: 27px;
		padding: 27px;
		height: $blockHeight !important; //override inline
		max-width: 33.3% !important;
		min-width: 32% !important;
		width: 100%;
		border: 1px solid #dedede;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
		border-radius: 8px;

		.optimization {
			&-label {
				margin-top: 20px;

				.led-elements {
					display: flex;
					flex-direction: column;

					> div > div:first-of-type {
						border-radius: 0 !important;
					}
				}
			}
		}

		.section-body {
			border-radius: 0;
		}

		.section-header {
			@include flex(center, flex-start);
			padding: 16px;
			background: $headerBackground;
			border-bottom: 1px solid $lightGrey;
			height: 55px;
			font-weight: 600;
			font-size: 18px;
			line-height: 20px;

			&:hover {
				cursor: pointer;
			}

			> div {
				white-space: nowrap;
				@include text-ellipsis();
			}
		}

		&.small {
			height: $autoHeight !important;
			max-width: 100%;
		}

		&.without-assembly-block {
			max-width: 50% !important;
		}

		.led-elements {
			display: grid;
			gap: 0.2em;
			margin-top: 7px;

			> div {
				> div {
					&:first-of-type {
						min-width: $svgSmall !important;
						width: $svgSmall !important;
						height: $svgSmall !important;
					}
				}
			}

			@include breakpoint(1280px) {
				grid-template-columns: 1fr;
				grid-template-rows: repeat(2, 20px);
			}

			@include breakpoint(1440px) {
				grid-template-columns: 1fr 1fr;
				grid-template-rows: repeat(2, 20px);

				> div {
					> div {
						&:first-of-type {
							min-width: $svgBig !important;
							width: $svgBig !important;
							height: $svgBig !important;
						}
					}
				}
			}

			> div {
				align-items: center;

				> div {
					min-width: 20px;
					margin-top: 0 !important;
				}
			}

			&.increased-gap {
				margin-top: 0.8em;

				@include breakpoint(1440px) {
					gap: 1em 0;
				}
			}
		}

		&.with-image {
			z-index: 0;
			padding: 0;
			height: $blockHeight !important;
			background: transparent;
			width: 33%;
			min-width: 33%;

			&:not(.gallery) {
				overflow: hidden;
			}

			@include breakpoint(1860px) {
				min-width: 420px;
			}

			@include breakpoint(2048px) {
				min-width: 520px;
			}

			.star-benefit-block {
				margin: 0;
			}

			.image-and-description-images {
				height: 460px;
			}
		}
	}

	&--no-parts {
		&--description {
			font-size: 1.2em;
			text-align: center;
			font-weight: bold;
		}
	}
}

.bolded {
	font-weight: bold !important;
}

.hidden {
	visibility: hidden;
	display: inline;
}

.slider_section {
	height: 100%;

	.fallback-wrapper svg,
	img {
		height: 100%;
	}

	&-header {
		@include flex(center, flex-start);
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		padding: 16px;
		background: $headerBackground;
		border-bottom: 1px solid $lightGrey;
		height: 55px;
		font-weight: 600;
		font-size: 18px;
		line-height: 20px;

		> div {
			white-space: nowrap;
			@include text-ellipsis();
		}

		@include breakpoint(xl) {
			font-size: 20px;
			line-height: 23px;
		}
	}

	&-image {
		background-color: $partBackground;
		@include flex(center, center);
		width: 100%;
		height: 100%;

		img {
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
			pointer-events: all;
		}
	}

	.slick-arrow {
		&.slick-next,
		&.slick-prev {
			&:before {
				color: black;
			}
		}
	}

	&-item {
		height: 100%;
		position: relative;
	}

	&--thumb {
		height: 100px;
		background: $headerBackground;
		padding: 10px 0 10px 4px;
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;

		.slick-slide {
			> div {
				margin-right: 5px;
			}
		}

		.slider_section-item {
			border: 2px solid $headerBackground;
		}

		.with-border {
			border: 2px solid darken($lightGrey, 15);
		}
	}

	&--thumb,
	&--main {
		.slick-list,
		.slick-track,
		.slick-slide {
			height: 100%;
		}

		.slick-slide {
			> div {
				height: 100%;
			}
		}
	}

	&--main {
		height: calc(100% - 55px);

		&.with-thumbs {
			height: calc(100% - 155px);
		}
	}
}

.form-control-root {
	margin-left: 0 !important;
}
