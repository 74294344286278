@import '../../../../style/globalStyles.scss';
@import '../../../../assets/style/base/colorsTheme.scss';

.admin-projects {
	&--table {
		table {
			border-collapse: separate !important;
			border-spacing: 0 10px;
			padding: 0 5px;
			height: 100%;
			th,
			td {
				border-bottom: none;
				padding: 12px !important;
				&:nth-child(4) {
					width: 200px;
					max-width: 250px;
				}
				&:nth-child(1) {
					width: 335px;
					max-width: 335px;
				}
			}
			th {
				color: $text-regular;
				font-weight: 500;
			}
			tbody {
				tr {
					box-shadow: 0 0 10px $cardShadow;
					border-radius: 8px;
					height: 100%;
					p {
						margin-bottom: 3px;
						span {
							font-weight: 400;
						}
					}
				}
			}
		}
		&--project-name {
			font-weight: 500;
		}
		.table-loader {
			width: 100% !important;
			left: 0;
		}
		&.parts-table {
			&.with-checkboxes {
				th,
				td {
					width: auto !important;
					&:nth-child(5) {
						width: 200px;
						max-width: 250px;
					}
					&:nth-child(2) {
						width: 345px !important;
						max-width: 345px !important;
					}
					&:nth-child(1) {
						width: 50px !important;
						max-width: 50px;
					}
				}
			}

			tbody {
				p,
				h4 {
					margin: 3px 0;
				}
			}
		}
		&.clusters-table {
			tbody {
				p,
				h4 {
					margin: 3px 0;
				}
			}
		}
	}
	&--content-wrapper {
		padding: 15px 15px !important;
	}
	&--project-status {
		padding: 2px 10px;
		border: 1px solid;
		border-radius: 100px;
		&.published {
			border-color: darken($success, 5);
			background-color: rgba(darken($success, 5), 0.2);
			color: darken($success, 5);
		}
		&.awaitingAnalysis {
			border-color: darken($chartMethodColor, 5);
			background-color: rgba(darken($chartMethodColor, 5), 0.2);
			color: darken($chartMethodColor, 5);
		}
		&.failed {
			border-color: darken($warnColor, 5);
			background-color: rgba(darken($warnColor, 5), 0.2);
			color: darken($warnColor, 5);
		}
	}
	&--project-mark {
		padding: 2px 10px;
		border: 1px solid;
		border-radius: 100px;
		font-size: 14px;
		border-color: darken($button-primary, 5);
		background-color: rgba(darken($button-primary, 5), 0.2);
		color: darken($button-primary, 5);
		margin: 0 5px;
		vertical-align: top;
		&.assembly {
			border-color: darken($advancedText, 5);
			background-color: rgba(darken($advancedText, 5), 0.2);
			color: darken($advancedText, 5);
		}
	}
	&--assembly-part {
		padding: 2px 10px;
		border: 1px solid;
		border-radius: 100px;
		font-size: 14px;
		border-color: darken($advancedText, 5);
		background-color: rgba(darken($advancedText, 5), 0.2);
		color: darken($advancedText, 5);
		margin-left: 5px;
		vertical-align: top;
	}
}
.admin-data-table-export-data {
	margin-right: 5px !important;
}
.admin-data-table-search-field {
	margin: 0 40px 0 70px !important;
}
