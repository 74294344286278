@import '../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../style/globalStyles.scss';

.graph-chart {
	margin: 20px 20px 0;
	padding: 0 20px;
	min-height: 450px;
	width: 100%;

	.graph {
		overflow: hidden;
	}

	.option-title {
		font-weight: 500;
		font-size: 16px;
	}

	&--options {
		max-width: 800px;
		margin: 0 0 15px;
		align-items: center;

		> div {
			font-size: 14px;
			display: flex;
			align-items: center;
			width: 100%;
			margin: 5px 0 20px;

			> div {
				min-width: 40px;
				max-width: 200px;
			}
		}

		> .option-button {
			margin: 5px 0 5px;

			button {
				margin: 0;
			}
		}

		.number-field {
			width: 100%;
		}
	}

	button {
		width: 100%;
		min-width: 40px;
		max-width: 200px;
		height: 30px;
		font-size: 14px;
	}

	hr {
		position: relative;
		top: -10px;
		margin: 0 30px 0 25px;
		border: 0;
		height: 420px;
		border-left: 1px solid $lightGrey;
	}

	div:has(input) {
		&:before {
			border-color: $lightGrey;
		}
	}
}

.graph {
	circle {
		&:hover {
			cursor: pointer;
		}
	}
}

.apexcharts-tooltip {
	transition: none !important;
}

.generative-tooltip {
	font-size: 12px;
	max-height: 400px;
	padding: 8px;
	line-height: 16px;
	overflow: auto;

	.borderline,
	.failed,
	.notprintable,
	.printable {
		width: 5px;
		height: 5px;
		border-radius: 50%;
		background-color: green;
	}

	.borderline {
		background-color: rgb(252, 197, 0);
	}

	.notprintable {
		background-color: orange;
	}

	.failed {
		background-color: red;
	}

	div {
		max-width: 200px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	span {
		font-weight: 500;
		margin-right: 5px;
	}
}
