@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.pagination {
	font-size: 16px;
	font-weight: 400;

	svg {
		margin: 0 5px;

		&:hover {
			cursor: pointer;
			fill: $lightBlue;
		}

		&.disabled {
			fill: $lightGrey;
			pointer-events: none;
		}
	}

	&-show-of {
		color: $darkGrey;
		margin: 0 12px 0 0;
		font-weight: 300;
		font-size: 14px;
	}

	&-dropdown {
		margin: 0 10px;
		border: 1px solid $lightGrey;
		border-radius: 4px;

		&:hover {
			cursor: pointer;
		}

		input {
			z-index: 0;
			&:hover {
				cursor: pointer !important;
			}
		}

		svg {
			margin: 0;
			z-index: 1;
		}

		.select-with-icons--select--root {
			padding: 5px !important;
			min-width: 30px;
			justify-content: center;
		}
	}
}

.page-link {
	color: inherit;
	&:visited {
		color: inherit !important;
	}
	&:hover {
		color: inherit !important;
	}
}
