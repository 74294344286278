@import '../../../../assets/style/base/designTheme.scss';
@import '../../../../style/globalStyles.scss';

.customize-suggestions {
	transition: all 0.5s ease-in;

	&_wrapper {
		button {
			width: fit-content;
			align-self: flex-end;
		}

		.field-with-label {
			width: 120px !important;

			input {
				padding: 10px;
			}
		}

		label + div {
			margin-bottom: 20px;
			margin-left: 20px;

			span {
				margin-left: 5px;
			}

			p {
				width: 400px;
				margin: 0;
				text-transform: initial;
			}
		}
	}

	&_list-wrapper {
		button {
			width: fit-content;
			align-self: flex-end;
		}
	}

	&_radio-group {
		margin: 10px 20px 10px !important;

		label {
			margin-bottom: 10px;

			span {
				margin-right: 5px;
			}
		}
	}

	.am {
		margin-left: 20px;
	}
}
