@import '../../../assets/style/base/colorsTheme.scss';

.details-popup {
	&-main-popper {
		border-radius: 8px;
		background-color: $benefitsBackgroundOpac;
		padding: 10px;
		z-index: -1;
		visibility: hidden;
		height: 0;
		width: inherit;
		opacity: 0;
		white-space: normal;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		transition: 0.3s opacity ease-in-out;

		&.show {
			z-index: 999999;
			visibility: visible;
			opacity: 1;
			height: inherit;
		}
	}

	&-data {
		cursor: default;
		width: 340px;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.44;
		letter-spacing: normal;
		color: $regularBenefitsText;

		&.auto-width {
			width: auto;
		}
    
		&-benefits {
			width: auto;
			top: 10px;
		}

		.part-detail-title {
			color: $tooltip-title-font-color;
		}

		&.with-dropdown {
			width: inherit;
		}
	}

	&-target {
		display: flex;

		&--small {
			width: fit-content;
		}
	}

	&--contact-us {
		width: auto !important;
		background-color: $lightBlue !important;
		margin: 2px;

		> div {
			color: $whiteColor !important;
			width: auto;
			white-space: nowrap;
		}
	}
}
