@import '../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../style/globalStyles.scss';
.solution-custom-orientation {
	width: 750px !important;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);

	.castor-alert--body {
		overflow: hidden;
	}

	&-content {
		flex-direction: column;
		justify-content: space-around;

		.error {
			color: $warnColor;
			width: 100%;
			text-align: right;
		}

		.show-cylinder {
			> span {
				padding: 0 !important;
				margin-right: 5px;
			}

			&-icon {
				width: 20px;
			}

			&_info {
				white-space: nowrap;
			}
		}

		.text_field__label {
			text-transform: capitalize;
		}

		&-3dview {
			flex-direction: row;
			gap: 25px;

			&-viewer {
				border: 1px solid #8484843b;
				border-radius: 5px;
				position: relative;
				height: 300px;
				width: 60%;
				background-color: white;

				.x3dom-canvas {
					position: absolute;
					top: 0;
					left: 0;
				}

				&--non-present-issue {
					position: absolute;
					bottom: 0;
					width: 100%;
					display: flex;
					align-items: center;
					opacity: 0.79;
					background-color: $text-regular-opac;
					color: $text-white;
					padding-left: 20px;
					padding: 21px 20px 20px 23px;
					border-radius: 0 0 5px 5px;
				}
			}

			&-coordinates {
				flex-direction: column;
				justify-content: space-between;
				gap: 10px;
				width: 25%;

				* {
					font-size: 14px;
				}

				&--text-field {
				}

				.text_field__input {
					width: 100px;
				}

				&-input-field {
					.castor-MuiFormLabel-filled {
						margin-bottom: 0;
						margin-right: 10px;
					}
				}
			}
		}
	}
}
