@import '../../../../style/globalStyles.scss';
@import '../../../../assets/style/base/colorsTheme.scss';

.specific-tolerances {
	&--alert-body {
		padding: 30px;
		h3 {
			font-weight: 500;
			margin-top: 0;
		}
	}
	&--wrapper {
		width: 100%;
		height: 100%;
		display: grid;
		grid-template-columns: 40% 57%;
		gap: 3%;
	}
	&--pdf-viewer {
		box-shadow: 0 0 10px $cardShadow;
		border-radius: 8px;
		height: fit-content;
		position: relative;
		transform-style: preserve-3d;
		transition: all 0.8s ease-in;
		height: 550px;
		img {
			width: 100%;
			object-fit: contain;
		}
		&.img-view {
			transform: rotateY(180deg);
		}
		.pdf-preview--wrapp {
			backface-visibility: hidden;
			width: calc(100% + 1px);
			position: absolute;
			top: 0 !important;
			background-color: $whiteColor;
			height: 100%;
			border-radius: 8px;
			z-index: 900;
		}
		.fallback-wrapper {
			border-radius: 8px;
		}
	}

	&--group-header {
		font-weight: 500;
	}
	&--general-tolerance {
		@include flex(center, flex-start, row);
		gap: 5px;
		color: $text-regular-opac;
		margin: 20px 0;
		&--selector {
			width: 200px;
		}
	}
	&--group-wrapper {
		box-shadow: 0 0 10px $cardShadow;
		border-radius: 8px;
		padding: 5px 8px;
		margin-bottom: 10px;
		.error-placeholder {
			height: 21px;
		}
	}
	&--group-table {
		width: 100%;
		border-spacing: 0;
		margin-bottom: 10px;
		&.disabled {
			pointer-events: none;
		}
		thead {
			th {
				font-weight: 400;
				color: $blackColor !important;
				padding: 0 5px;
				background-color: rgba(darken($button-primary, 5), 0.2) !important;
				border: 1px solid rgba(darken($button-primary, 5), 0.2) !important;
				vertical-align: middle !important;

				&:nth-child(1) {
					width: 170px;
					min-width: 170px;
				}
				&:nth-child(5) {
					width: 150px;
					min-width: 150px;
				}
				&:nth-child(2) {
					width: 90px;
					min-width: 90px;
				}

				@media screen and (max-width: 1370px) {
					&:nth-child(1),
					&:nth-child(5) {
						width: unset;
					}
				}
			}
		}
		tbody {
			td {
				height: 25px !important;
				border: none !important;
				border-bottom: 1px solid $cardShadow !important;
				border-right: 1px solid $cardShadow !important;
				input {
					height: 25px !important;
				}
				&:nth-child(1) {
					border-left: 1px solid $cardShadow !important;
					box-shadow: none !important;
					cursor: default !important;
				}
				&.read-only {
					background: unset !important;
					color: inherit !important;
				}
			}
		}
	}
	&--tolerance-name {
		@include flex(center, center, row);
		span {
			@include text-ellipsis;
			max-width: 100%;
			font-weight: 600;
		}
		svg {
			width: 24px;
			height: 24px;
			margin-left: 5px;
		}
		@media screen and (max-width: 1370px) {
			flex-direction: column;
		}
	}
	&--tolerance-image {
		height: 100%;
		width: 100%;
		&--wrapper {
			@include flex(center, center, row);
			position: absolute;
			height: 100%;
			width: 100%;
			transform: rotateY(180deg);
			top: 0;
			right: 0;
			background-color: $whiteColor;
			border-radius: 8px;
		}
		&--inner-wrapper {
			position: relative;
			height: 100%;
			width: 100%;
		}
		&--close-btn {
			position: absolute;
			right: 10px;
			top: 10px;
			cursor: pointer;
			z-index: 1000;
		}
	}
	&--show-image-icon {
		cursor: pointer;
		width: 20px !important;
		height: 20px !important;
		&--wrapper {
			@include flex(center, center, row);
			height: 100%;
		}
		path {
			fill: $button-primary;
		}
	}
	&--sticky-container {
		position: sticky;
		top: 0;
		perspective: 1000px;
	}
}
