@import '../../../../style/globalStyles.scss';
@import '../../../../assets/style/base/colorsTheme.scss';

.admin-parts {
	&--parts-table {
		&--loader {
			align-items: center;
		}
	}
}
.dev-mode-toggle {
	@include flex(center, flex-start, row);
	gap: 5px;
	position: absolute;
	right: 15px;
	top: -50px;
	font-weight: 400;
	font-size: 16px;
	svg {
		path {
			fill: $button-primary;
		}
	}
}
.parts-table {
	&--wrapper {
		position: relative;
	}
}
