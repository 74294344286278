@import '../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../style/globalStyles.scss';

.detail-title {
	color: #000000;
	font-size: 14px;
	min-height: 28px;
	@include flex(center, center);

	&.suggestion {
		max-width: 60%;
	}
	&--wrapper {
		@include flex(flex-start, center, row);

		> span {
			display: inline-block;
		}
	}

	&--star {
		margin: 0 !important;
	}
}

.detail-body {
	font-weight: 400;
	font-size: 18px;
	line-height: 21px;

	> span {
		font-size: 12px;

		&:first-of-type {
			opacity: 0.8;
			margin-right: 5px;
		}
	}

	svg {
		width: 20px;
		height: 20px;
		margin-right: 5px;
	}

	.circle-load {
		img {
			bottom: 19px;
		}
	}

	img {
		bottom: -10px;
		position: relative;
	}

	.castor-detail-body--text {
		@include flex(flex-start, center);
		height: 100%;

		.additive-minds {
			button {
				margin: 0 !important;
				padding: 0 !important;
			}
		}

		> span {
			height: 100%;
		}
	}

	&--wrapper {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-start;
	}

	&--cost {
		@include flex(flex-start, space-between, column);
		height: 100%;

		&--wrapper {
			position: relative;
			@include flex(center, center, column);

			&,
			> div {
				&,
				> span {
					width: 100%;
				}
			}
		}

		&--divide {
			margin: 0 7px;
		}

		&--main {
			font-size: 0.8em;
		}

		&--secondary {
			color: $text-regular-opac;
			font-weight: 300;
			font-size: 14px;
			line-height: 16px;
		}

		&_range {
			@include flex(center, center, column);
			width: 100%;
			font-size: 12px;
			min-width: 245px;

			> div {
				@include flex(center, space-between, row);
				width: 100%;
				color: $text-regular-opac;
				line-height: 16px;

				b {
					font-weight: 500;
				}

				span + span {
					margin-left: 10px;
				}
			}

			.upper {
				margin-left: 5px;
				text-transform: uppercase;

				> span {
					text-transform: initial;
				}
			}

			&--value {
				@include text-ellipsis();
			}
		}

		&.lead {
			position: relative;
		}
	}

	.grey {
		font-size: 12px;
		color: $text-regular-opac;
	}
}

.detail-popper {
	left: 56px !important;
	top: 46px !important;
}

.middle-detail {
	padding: 0 46px;
	border: 2px solid $devider-color;
	border: {
		top: transparent;
		bottom: transparent;
	}
}

.solution-header {
	&-new-configuration {
		font-size: 26px;
		margin: 20px 0;
	}

	&--see-more {
		position: absolute;
		align-self: flex-end;
		left: 50%;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}

	&-no-configuration {
		flex-grow: 1;
		text-align: left;
		font-size: 18px;
		display: flex;
		align-items: center;
		color: $text-regular-opac;
		fill: $text-regular-opac;
		margin-left: 20px;

		&-devider {
			margin: 0 3%;
		}

		&-text-icon {
			margin: 0 10px;
			width: 15px;
		}

		&-button {
			margin: 0 !important;
			text-transform: capitalize !important;
			padding: 8px 22px !important;

			&:hover {
				.solution-header-no-configuration-button-icon {
					fill: $button-hover-color;
				}
			}

			&-icon {
				fill: $whiteColor;
				width: 22px;
				margin-right: 10px;
			}
		}
	}
}

.time-save-icon {
	top: -9px;
	left: 73px;
	height: 19px;
	width: 19px;
}

.solution-analysis-header-benefits-data {
	font-size: 16px;
	text-align: start;

	&-icon {
		width: 13px;
		margin: 0 5px -8px 0;
		fill: $regularBenefitsText;
	}

	&-circle {
		width: 24.6px;
		height: 24.6px;
		border: solid 1.9px #ffffff;
		border-radius: 13px;
		margin-right: 4px;
	}

	&-lines {
		width: 9.6px;
		height: 8.6px;
		border-bottom: solid 1.8px #ffffff;
		border-left: solid 1.8px #ffffff;
		position: relative;
		left: 7px;
		top: 6px;
	}

	&-title {
		font-weight: bold;
		font-size: 17px;
	}
}

.title-wrapper {
	@include flex(center, space-between, row-reverse);
	font-size: 16.5px;
	width: 100%;

	&.start {
		align-items: flex-start;
	}
}

.cost-details {
	&-icon {
		margin-left: 4px;
		width: 14px;
		height: 14px;
	}

	&-wrapper {
		line-height: 26px;
		color: $text-regular-opac;
		position: relative;

		&--warning {
			text-align: left;
			font-size: 14px;
			line-height: 1.44;

			> div:first-of-type {
				margin: 10px 0;
			}
		}
	}

	&-post-process {
		display: flex;
		flex-direction: column;
	}

	&-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 2px;

		&.center {
			align-items: center;
			flex-direction: row;
		}

		&-total {
			color: $text-regular;
		}

		&-header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 18px;
		}

		&-divider {
			width: 228px;
			height: 1px;
			border: solid 1px $regular-opac;
			margin-top: 10px;
			margin-bottom: 2px;

			&--last {
				margin-top: 2px;
			}
		}

		span {
			margin-left: 5px;
		}

		&--with-edit {
			@include flex(center, space-between, row);
			width: 100%;
			margin-bottom: 10px;
		}
		&--edit-button {
			width: auto;
		}
		&--name {
			@include text-ellipsis();
		}
	}
}

.info-box {
	&-details {
		&#{&} {
			background-color: $tooltip-background-color;
			white-space: nowrap;
			border-radius: 8px;
			width: 370px;
		}
	}
}

.no-printable {
	max-width: 50%;

	&-explanation {
		margin-left: 25px;
		text-align: left;
		max-width: 100%;
		display: flex;
	}

	&-reason {
		@include text-ellipsis();
	}

	&-extra-reasons {
		background-color: $lightGrey;
		margin-left: 5px;
		border-radius: 4px;
		padding: 0 6px;
	}

	.detail-body {
		@include flex(flex-start, center, column);
		max-width: 100%;
	}

	.castor-detail-body--text {
		min-height: 30px;
	}
}

.unprintable-to-print {
	max-width: 100% !important;
	width: 100% !important;
}

.printing-standard {
	margin-bottom: 10px;
}
