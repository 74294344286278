@import '../../../../style/globalStyles.scss';
@import '../../../../assets/style/base/colorsTheme.scss';

.project-bundle {
	@include flex(center, space-around, row, wrap);
	text-align: left;
	margin-top: 20px;

	> div {
		width: 100%;
	}

	#general-parts-bundle-statistics {
		width: calc(100% - 30px);
		margin: 0 15px;
		z-index: 0;

		> div:first-of-type {
			display: none;
		}

		.general-part-statistics {
			width: 100%;
			overflow: auto;

			&,
			ul {
				width: inherit;
				min-height: 400px !important; //override inline

				@include breakpoint(1440px) {
					min-height: 500px !important; //override inline
				}

				@include breakpoint(1680px) {
					min-height: 600px !important; //override inline
				}
			}

			ul {
				margin: 0 !important; //override inline
				box-shadow: none !important; //override inline
			}

			.parts-pagination {
				min-height: 24px !important;
				margin-top: 24px !important;
			}
		}
	}
}
