.tolerance-cost {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;

	> div:first-of-type {
		margin-bottom: -40px;
		align-self: flex-start !important;
	}
}

.tolerance-picker {
	width: 90%;

	&__input {
		&-field {
			width: 90px !important;
		}
	}

	&--select {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		width: 9rem;
	}
}

.tolerance-input-error {
	position: absolute;
	font-size: 11px !important;
	width: 100%;
}
