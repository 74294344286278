@import '../../../../../../../../../style/globalStyles.scss';
@import '../../../../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../../../../assets/style/base/designTheme.scss';

.financial-form {
	&-buttons {
		align-self: flex-end;
		position: fixed;
		bottom: -25px;
		display: flex;
		width: 25%;
		justify-content: flex-end;
	}

	&-yAxisLabel {
		transform: rotate(90deg);
		position: absolute;
		transform-origin: -21px 39px;
	}

	&-xAxisLabel {
		text-align: center;
	}

	&-chart {
		min-height: $cost-analysis-chart-height;
		position: relative;
		width: calc(100% - 5px); //to not override shadow

		&-datapoint-hover {
			width: 200px;
			background-color: $button-primary;

			&-cost {
				font-weight: bold;
			}
		}

		&.with-border {
			border: 1px solid $tableButtonsBorder;
			background: $tableButtonsBorder;
			border-radius: 6px;
			box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.16);

			.graphic-loader {
				width: 100% !important; //override inline style
				height: 100% !important; //override inline style
			}
		}
	}

	&-quantity {
		align-self: flex-start;
		width: 90px;

		#plastic-form-quantity {
			text-align: center;
			color: $text-regular-opac;
		}
	}

	&-explenation {
		text-align: initial;
	}

	&--text-field {
		margin: 9px 0 0 !important;

		&-wrapper {
			margin: 0 0 5px;
		}

		&-label {
			font-size: 14px !important;
			margin: 0;
		}

		&-input {
			min-width: 180px;
		}
	}

	&--popup-explanation-text {
		margin-top: 6px;
	}

	&-advanced-button {
		margin: 10px 0 0 !important;
		align-self: flex-start;
	}

	&-button {
		margin: 0 20px !important;
		text-transform: none !important;
		font-size: 14px !important;

		&-submit {
			text-transform: initial !important;
		}
	}

	&-edit {
		@include flexible-row();
		justify-content: flex-start;
		margin: 3px 0;
	}

	&-user-custom-settings-header {
		display: flex;
		margin-top: 14px;
		font-size: larger;
		font-weight: 400;
	}

	&-chain-benefits-wrapper {
		margin-top: 10px;

		&-header {
			display: flex;
			font-size: larger;
			font-weight: 400;
		}
	}
}

.cost-comparison-tab {
	&--table {
		opacity: 0;
		z-index: -1;
		position: absolute;
		width: 100%;
		@include flex(flex-end, center, column);
		transition: opacity 0.3s ease-in-out;

		&.show {
			opacity: 1;
			height: unset;
			position: relative;
			z-index: 1;
			overflow: hidden;
			.tm-selector {
				align-self: flex-start;
				padding-left: 20px;
				margin-top: 5px;
			}
			.castor-devider {
				width: 99%;
				align-self: center;
			}
		}

		&--loader {
			height: 90% !important;
			opacity: 0.8;
		}

		&--wrapper {
			width: 99%;
			align-self: flex-start;
			height: 285px;
			overflow: auto;
			margin-bottom: 5px;

			&::-webkit-scrollbar {
				width: 5px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: darkgrey;
				border-radius: 10px;
			}
		}

		&--text-with-icon {
			width: $table-column-with-text-width;
			color: inherit;
			justify-content: space-between;

			&--icon {
				margin: 0;
				width: 20px;
				height: 20px;
				min-width: unset;
				min-height: unset;
			}
		}
	}

	&--chart {
		opacity: 1;
		transition: opacity 0.3s ease-in-out;

		&.hide {
			opacity: 0;
			height: 0;
			overflow: hidden;
		}
	}

	&--standard-cost {
		height: 220px;
		position: relative;

		&--label {
			height: auto;
			margin: 25px 25px 0 0;
			font-size: 16.5px;

			&--success {
				color: $benefitsText;
			}

			&--body {
				font-size: 23px;
			}

			&--title {
				margin-bottom: 10px;
				white-space: normal;
				text-align: left;
			}

			&--space {
				margin-top: 122px;
			}
		}
	}

	&--toggle--root {
		> span:first-child {
			height: unset;
		}
	}

	&--switches {
		@include flex(start, center, column);

		&--top {
			margin-bottom: 0px !important;
		}
	}

	&--information {
		@include flexible-row;
		align-items: flex-start;
		text-align: left;
		margin-top: 10px;

		&--cost-explenation {
			text-align: left;
			display: flex;
			align-items: center;
			font-weight: bold;
			line-height: 16px;

			&--icon {
				width: 15px;
				height: 15px;
				margin-right: 7px;
			}
		}

		.nowrap {
			white-space: nowrap;
		}
	}

	&--cost-explenation {
		text-align: left;
		display: flex;
		align-items: center;
		font-weight: bold;
		line-height: 16px;

		&--icon {
			width: 15px;
			height: 15px;
			margin-right: 7px;
		}
	}

	.edit-parameters {
		height: 290px;
		overflow: auto;
	}
}

.check-boxs {
	margin-top: 15px;

	&--header {
		text-align: initial;
		// margin-right: 20px;
		margin-top: 16px;

		&.note {
			font-size: 12px;
		}

		&.top {
			margin-top: 9px;
			margin-bottom: 10px;
			width: 280px;
		}
	}

	&--subset {
		margin-left: 19px;
	}

	&--wrapper {
		display: flex;
		align-items: center;
		// @include flex(center, space-between);
	}

	&--info {
		margin-right: 5px;
	}

	&--icon {
		&#{&} {
			width: 30px;
			height: 30px;
		}
	}

	&--label {
		&#{&} {
			margin-left: -3px;
		}

		&.subhead {
			.details-popup-target {
				font-size: 16px;
				margin-bottom: 0px;
			}
		}

		&.subset {
			.details-popup-target {
				margin-bottom: 0px;
			}
		}
	}

	&-with-info {
		display: flex;
		align-items: center;
		margin-left: 14px;

		> span {
			width: 18px;
			height: 18px;
		}

		.info-in-checkbox {
			margin: 0 11px 0 14px;

			svg {
				width: 12px;
			}
		}
	}

	&-popup {
		width: 261px;
		border-radius: 8px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		background-color: $long-text-read-more-background;
	}
}

.info-box {
	&-wrapper {
		width: 261px;
	}

	&-data {
		width: 215px;
	}

	&--info {
		width: 261px;
		margin-right: 5px;
	}

	&--icon {
		margin-left: -7px;
		margin-top: 7px;
	}
}

.custom-tooltip {
	padding: 5px;
	text-align: left;

	&.with-standard {
		.am {
			background-color: $chartMethodColor;
		}

		.default {
			background-color: $chartAMColor;
		}
	}

	&.flex {
		display: flex;
		align-items: center;
	}

	> div {
		display: flex;
		align-items: center;
	}

	.single-dot {
		display: flex;
		flex-direction: column;
	}

	.am,
	.default {
		width: 5px;
		height: 5px;
		border-radius: 50%;
		margin-right: 5px;
	}

	.cross {
		background-color: $chartCrossColor;
	}

	.am {
		background-color: $chartAMColor;
	}

	.default {
		background-color: $chartMethodColor;
	}
}

.apexcharts {
	&-legend {
		left: 80px !important; //override inline style
		justify-content: flex-end !important; //override inline style
		bottom: 5px !important;

		&-series,
		&-text {
			cursor: pointer;
		}
	}
}

.seriesName {
	&-mold,
	&-am {
		padding: 3px 5px;
		border-radius: 3px;
	}

	&-mold {
		background-color: $chartMethodColor;
	}

	&-am {
		background-color: $chartAMColor;
	}
}

.co-tooltip {
	width: 100%;
	margin: 0 0 10px;
	padding-right: 5px;

	span.small-letter {
		font-size: 9px;
		bottom: -2px;
		position: relative;
	}

	&,
	.details-popup-target {
		display: flex !important;
		justify-content: flex-end;
		align-items: center;
		margin: 2px 0 7px;
	}

	&-information {
		&__text {
			@include flex();
			justify-content: flex-end;

			svg {
				margin: 0;
			}

			&:hover {
				cursor: pointer;
			}

			div.text {
				font-size: 12px;
				font-weight: 600;
				color: #3c4858;
				margin-left: 6px;
			}
		}
	}
	.grayed {
		align-items: center;
		margin: 2px 0 7px;
		svg {
			path {
				fill: $darkGrey;
			}
			g {
				path {
					fill: $whiteColor;
				}
			}
		}
		&:hover {
			cursor: default;
		}
	}
}

.co2-parameters {
	display: flex;
	flex-direction: column;
	justify-content: start;
	padding: 20px 0;

	&-header {
		text-align: start;
		margin-top: 14px;
		margin-bottom: 5px !important;
		font-size: larger;
		font-weight: 400;
	}

	&-description {
		text-align: start;
	}

	.suffix {
		margin-left: 5px;
		font-size: 14px !important;
	}

	.thickness-layer {
		position: relative;
	}

	.error {
		margin-top: 2px;
		color: $warnColor;
		text-align: start;
		height: 10px;
	}

	.text_field__input {
		background-color: transparent !important;
	}

	.plus-button,
	.minus-button {
		padding: 10px !important;
	}

	.initial-value {
		font-size: 10px;
		color: $darkGrey;
		margin: 0;
		position: absolute;
		bottom: -5px;
		right: 48px;
	}
}

.co2-details {
	&-wrapper {
		width: 340px;

		&#{&} {
			left: -160px !important;
			top: -5px !important;
			border-radius: 8px;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
			background-color: $long-text-read-more-background;
		}
	}
}

.customize-material-selector {
	&--material-name {
		> div {
			@include flex();
		}
	}
}
.no-original-design-solution-warning {
	@include flex(center, flex-start, row);
	gap: 5px;
	padding: 5px 0 10px;
	svg {
		fill: $darkGrey;
	}
	a {
		cursor: pointer;
		text-decoration: underline;
		font-weight: 400;
		color: $button-primary !important;
		&:visited {
			color: inherit;
		}
	}
}
.no-combined-cost-details-wrapper {
	@include flex(center, center, row);
	font-size: 1.2em;
	width: 100%;
	height: 300px;
	padding: 20px;
}
.no-combined-cost-details {
	max-width: 90%;
	svg {
		fill: $darkGrey;
		margin-right: 5px;
		vertical-align: middle;
	}
	a {
		cursor: pointer;
		text-decoration: underline;
		font-weight: 400;
		color: $button-primary !important;
		&:visited {
			color: inherit;
		}
	}
}
.header-with-warning {
	@include flex(center, flex-start, row);
	gap: 5px;
	svg {
		left: 0 !important;
		top: 0 !important;
		width: 16px !important;
		height: 16px !important;
		min-width: 0 !important;
		min-height: 0 !important;
	}
}
.assembling-cost {
	&--wrapper {
		height: 35px;
		display: grid;
		grid-template-columns: auto 1.3fr 0.7fr;
		align-items: center;
		gap: 10px;
	}
	&--info-icon {
		width: 16px !important;
		height: 16px !important;
		min-width: 16px;
		min-height: 16px;
	}
	&--rendered-value {
		@include flex(center, flex-start, row);
	}
	&--alert-body {
		overflow: hidden !important;
	}
}