@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';

.castor-detail {
	position: relative;
	@include flex(flex-start, flex-start, column);

	&-title {
		color: #000000;
		white-space: nowrap;
		min-height: 28px;
		font-size: 14px;
		text-align: start;
	}

	.part-detail-title {
		color: $tooltip-title-font-color;
		font-weight: $castor-detail-title-font-weight;
		font-size: $castor-detail-title-font-size;
	}

	&-body {
		color: $tooltip-body-font-color;
		@include flex(space-around);
		height: 100%;
		white-space: nowrap;

		.suggestion-type {
			text-transform: capitalize;
		}

		&--extra-details {
			font-size: 14px;
			margin-left: 3px;
			color: $text-regular-opac;

			&.small {
				@include flex(center, flex-start);
				margin-left: 0;
				margin-top: 7px;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;

				.dark {
					color: #000000;
					margin-left: 5px;
					max-width: 100px;
					@include text-ellipsis();
				}

				.details-popup-target {
					margin-bottom: 0;
				}
			}
		}

		&--text {
			&.icon {
				min-height: 45px;
			}
		}
	}

	.bold {
		font-weight: 500;
		font-size: 15px;
	}
}
