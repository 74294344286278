@import '../../../../../../style/globalStyles.scss';
@import '../../../../../../assets/style/base/colorsTheme.scss';

.parts-list {
	&--wrapper {
		width: 100%;
		padding: 0 16px;
		min-height: 386px;
		text-align: left;
		&.empty {
			padding: 0;
		}
	}
	&--header {
		display: grid;
		width: 100%;
		grid-template-columns:
			94px minmax(150px, 1fr) minmax(150px, 1fr) minmax(100px, 1fr) repeat(
				3,
				170px
			)
			70px;
		&.in-quick-cad-upload {
			grid-template-columns:
				94px minmax(150px, 1fr) minmax(150px, 1fr) minmax(100px, 1fr)
				repeat(3, minmax(100px, 1fr)) 170px;
		}

		&.is-generative-parts {
			grid-template-columns:
				94px
				minmax(125px, 1fr)
				minmax(125px, 1fr)
				minmax(125px, 1fr)
				minmax(125px, 1fr)
				minmax(125px, 1fr)
				minmax(125px, 1fr)
				minmax(125px, 1fr)
				minmax(125px, 1fr)
				minmax(150px, 1fr)
				minmax(150px, 1fr)
				minmax(150px, 1fr)
				70px;

			> div {
				max-width: 80%;
				text-wrap: pretty;
			}
		}

		&--item {
			@include flex(center, flex-start, row);
			padding-left: 16px;
			font-weight: 500;
			gap: 5px;
			white-space: nowrap;
		}
		&--info-icon {
			width: 14px;
		}
	}
	&--body {
		width: 100%;
	}
	&--part-row {
		width: 100%;
		display: grid;
		grid-template-columns:
			94px minmax(150px, 1fr) minmax(150px, 1fr) minmax(min-content, 1fr) repeat(
				3,
				170px
			)
			70px;
		align-items: center;
		border-radius: 8px;
		box-shadow: 0 0 10px $cardShadow;
		height: 60px;
		margin: 16px 0;
		cursor: pointer;
		&:hover {
			background-color: $hoverPartColor;
			.part-actions--remove {
				opacity: 1;
			}
		}
		&--extra-reasons {
			background-color: $lightGrey;
			margin-left: 5px;
			padding: 2px 6px;
			border-radius: 4px;
		}
		&.in-quick-cad-upload {
			grid-template-columns:
				94px minmax(150px, 1fr) minmax(150px, 1fr) minmax(100px, 1fr)
				repeat(3, minmax(100px, 1fr)) 170px;
			cursor: auto;
		}
		&.is-generative-parts {
			grid-template-columns:
				94px
				minmax(125px, 1fr)
				minmax(125px, 1fr)
				minmax(125px, 1fr)
				minmax(125px, 1fr)
				minmax(125px, 1fr)
				minmax(125px, 1fr)
				minmax(125px, 1fr)
				minmax(125px, 1fr)
				minmax(150px, 1fr)
				minmax(150px, 1fr)
				minmax(150px, 1fr)
				70px;
		}
		&--cell-img {
			width: 90px;
			height: 60px;
			border-radius: 8px 0 0 8px;
			background-color: $partBackground;
			padding: 5px;
			&.drawing {
				padding: 0;
				background-color: transparent;
			}
			.cell-fallback-img {
				border-radius: 8px 0 0 8px;
				svg {
					transform: scale(1.5);
				}
			}
			.cell-img {
				border-radius: 8px 0 0 8px;
				height: auto;
				width: 100%;
				max-width: 100%;
				max-height: 100%;
				object-fit: cover;
			}
		}
		&--cell-text {
			@include flex(center, flex-start, row);
			@include text-ellipsis();
			white-space: nowrap;
			padding-left: 16px;
			font-weight: 400;
			span,
			div {
				@include text-ellipsis();
				z-index: 1;
			}
			&.pure-text {
				display: inline-block;
			}
			&.with-info {
				@include flex(center, flex-start, row);
				gap: 5px;
			}
			&.wrap-pretty {
				width: 60%;
				text-wrap: pretty;
			}
			&.star-delete {
				padding-right: 16px;
				padding-left: 0;
				.part-actions {
					position: relative;
					top: 0;
					left: 0;
					width: auto;
					gap: 8px;
				}
			}
			.card--result {
				&-label-text {
					margin-right: 12px;
				}
			}
		}
		&--three-d {
			width: auto;
		}
		.defaultText {
			font-style: italic;
			padding-right: 2px;
		}
	}
}
.three-d-button-popup {
	padding: 0;
	border-radius: 0;
	box-shadow: none;
	background: transparent;
	z-index: 4 !important;
	&--data {
		width: auto;
	}
}
.not-cost-effective-popup {
	width: auto;
	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	background-color: $tooltip-background-color;
	&--data {
		width: auto;
		font-size: 16px;
		letter-spacing: normal;
		color: $tooltip-body-font-color;
		text-align: left;
	}
}

.project-part-list {
	overflow: hidden;
	min-width: unset !important;

	> div {
		overflow-x: auto;
	}

	.parts-list--wrapper {
		min-width: fit-content;
		width: auto;
	}
}
