.admin-project-form {
	&--field {
		width: 50%;
		margin: 10px 0;
		text-transform: capitalize;

		&--text-field-wrapper {
			margin: 0 0 5px;
			width: unset;
		}

		&--text-field-label {
			margin: 0;
		}
	}
}

.admin-project-edit-form {
	&--field {
		width: 50%;
		margin: 5px 10px;
		text-transform: capitalize;

		&--text-field-wrapper {
			margin: 0 0 5px;
			width: unset;
		}

		&--text-field-label {
			margin: 0;
		}
	}
}
